import type { Opportunity } from '~/interfaces/opportunities/main';

export const useOpportunitiesStore = defineStore('OpportunitiesStore', () => {
  const route = useRoute();
  const editSection = ref<string>('family-situation');
  const hasCoborrower = ref<boolean>(false);
  const advancedMode = ref<boolean>(false);
  const opportunities = ref<Opportunity[]>([]);
  const opportunity = ref<Opportunity | null>(null);
  const opportunityId = computed(() => opportunity.value ? opportunity.value.hashId : route.params.opportunityRefId);
  const selectedOpportunities = ref<Opportunity[]>([]);
  const loading = ref<boolean>(false);
  const error = ref(null);
  const totalRecords = ref<number>(0);

  async function fetchOpportunities (params: string) {
    opportunities.value = [];
    loading.value = true;
    const urlParams = params ? '?' + params : '';
    try {
      const response = await useAuthFetch(`opportunities${urlParams}`);
      ({ data: opportunities.value } = response.data.value);
      ({ totalItems: totalRecords.value } = response.data.value.meta);
    } catch (error) {
      error.value = error;
    } finally {
      loading.value = false;
    }
  }

  async function fetchOpportunity (hashId: string) {
    loading.value = true;
    try {
      const response = await useAuthFetch(`opportunity/${hashId}`);
      ({ data: opportunity.value } = response.data.value);
    } catch (error) {
      error.value = error;
    } finally {
      loading.value = false;
    }
  }

  return {
    editSection,
    hasCoborrower,
    advancedMode,
    opportunities,
    opportunity,
    opportunityId,
    selectedOpportunities,
    loading,
    error,
    totalRecords,
    fetchOpportunities,
    fetchOpportunity,
  };
});
